.scrollbar::-webkit-scrollbar {
  width: 7px;
  height: 10px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 0;
  background: rgb(247, 247, 247);
}

.scrollbar::-webkit-scrollbar-thumb {
  background: rgb(6 192 212);
  border-radius: 0;
  border: 2px solid rgb(247, 247, 247);
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgb(6 192 212 / 0.8);
}

.line {
  height: 1px;
}

input:focus {
  outline: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #f7f8f8 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.create-system {
  width: 700px;
  max-height: 800px;
}

.popup {
  animation: popup-transition 0.3s;
}

@keyframes popup-transition {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.filter {
  animation: filter-transition 0.2s;
}

@keyframes progessbar-trainsition {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}

.progress {
  animation: progessbar-trainsition 4s;
  animation-fill-mode: forwards;
}

@keyframes filter-transition {
  from {
    clip-path: inset(0 0 0 100%);
  }

  to {
    clip-path: inset(0 0 0 0);
  }
}

.overlay-modal {
  animation: overlay-modal-transition 0.3s;
}

@keyframes overlay-modal-transition {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.49;
  }
}

ol li {
  list-style: decimal;
  list-style-position: outside;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.apexcharts-legend {
  margin-top: -20px;
  font-size: 16px;
}
.apexcharts-legend-series{
  display: flex;
}
.apexcharts-legend-text {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}